import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [

  {
    image: image1,
    review:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem adipisicing elit ",
    name: 'BRUNO HENDRICKSON',
    status: 'EMPREENDEDOR'
  },

  {
    image: image2,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'JOHN KEVIN',
    status: 'TREINADOR'
  },

  {
    image: image3,
    review: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'MATHEUS FRANKLIN',
    status: "CLIENTE"
  }

];
